import styled from 'styled-components'

export default styled.div`
  .hero {
    &-tag {
      margin-top: 16px;
      display: block;
      color: #4951ea;
      font-family: 'Open Sans';
      font-size: 20px;
      font-weight: bold;
      line-height: 27px;
    }
    &-title {
      color: #333333;
      font-family: 'Poppins', sans-serif;
      font-size: 50px;
      font-weight: bold;
      line-height: 76px;
      margin: 0;
      > span {
        color: #ff7201;
      }
    }
    &-desc {
      color: #555555;
      font-family: 'Open Sans';
      font-size: 18px;
      line-height: 29px;
      width: 95%;
    }
    &-banner {
      position: relative;
      padding-top: 110px;
      @media screen and (min-width: 1440px) {
        padding-top: 130px;
      }
      &--background {
        display: block;
        width: 71%;
        position: absolute;
        top: 0;
        right: 0;
        @media screen and (min-width: 1440px) and (max-width: 1600px) {
          width: 73%;
        }
        @media screen and (min-width: 1600px) and (max-width: 1900px) {
          width: 72%;
        }
      }
      &--people {
        display: block;
        position: absolute;
        top: 103px;
        right: 180px;
        @media screen and (min-width: 1440px) {
          right: 220px;
        }
      }
    }
    &-seo {
      color: #333333;
      text-transform: uppercase;
      font-size: 20px;
      margin-top: 16px;
      margin-bottom: 0px;
      font-weight: bold;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .hero-title {
      font-size: 32px;
      line-height: 35px;
    }

    .hero-banner--background {
      width: 45%;
      height: auto;
    }

    .hero-banner--people {
      right: 0;
      width: 60%;
      height: auto;
    }

    .hero-desc {
      font-size: 15px;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 768px) {
    .hero-title {
      font-size: 22px;
      line-height: 35px;
    }

    .hero-banner {
      padding-top: 15px;
    }

    .hero-banner--background {
      display: none;
    }

    .hero-banner--people {
      position: relative;
      top: 0;
      right: 0;
      margin-top: 105px;
      width: 100%;
    }

    .width-reset {
      width: unset;
    }

    .width-reset .col-xs-6.col-md-6.col-lg-6 {
      flex-basis: unset;
      max-width: 100%;
    }
  }
`
