import styled from 'styled-components'

export default styled.section`
  display: flex;
  position: relative;
  margin: 140px 0 41px 0;
  @media screen and (min-width: 300px) and (max-width: 767px) {
    margin: 25px 0 41px 0;
    display: flex;
    position: relative;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    margin: 95px 0 41px 0;
    display: flex;
    position: relative;
  }
  @media screen and (min-width: 1440px) and (max-width: 1600px) {
    margin: 160px 0 41px 0;
    display: flex;
    position: relative;
  }
  @media screen and (min-width: 1600px) and (max-width: 1900px) {
    margin: 190px 0 41px 0;
    display: flex;
    position: relative;
  }
  .about {
    &-background {
      z-index: -1;
      background: linear-gradient(
        180deg,
        rgba(255, 178, 0, 0) 0%,
        rgba(255, 115, 0, 0.3) 100%
      );
      right: 0;
      bottom: 65px;
      left: 0;
      width: 100%;
      height: 200px;
      position: absolute;
    }
    &-waves {
      position: absolute;
      right: 0;
      bottom: 65px;
      > img {
        display: block;
      }
    }
    &-content {
      display: flex;
      width: 100%;
      &--left {
        padding: 0;
        > img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      &--right {
        padding: 10px;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .about-link {
    &:hover {
      color: #ff7c00;
    }
    text-decoration: none;
    color: #555555;
    cursor: pointer;
  }
  .about-description {
    z-index: 5;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .about-us-title {
      font-size: 23px;
    }

    .about-desc {
      font-size: 15px;
    }

    .about-waves {
      display: none;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 767px) {
    .about-image {
      flex-basis: unset;
      max-width: 100%;
    }

    .about-description {
      flex-basis: unset;
      max-width: 100%;
    }

    .about-background {
      display: none;
    }

    .about-waves {
      display: none;
    }
  }
  .paragraph-wrap {
    margin-top: 28px;
  }
`
