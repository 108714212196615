import React from 'react'

import arrowRight from '../../static/icons/ic-arrow-right.png'
import leftBlue from '../../static/icons/ic-left-blue.svg'
import arrowLeft from '../../static/icons/ic-arrow-left.png'
import rightBlue from '../../static/icons/ic-right-blue.svg'
import { NextArrowWrap, PrevArrowWrap } from './arrow-slider.styles'

export const SampleNextArrow = props => {
  const { className, style, onClick, slider } = props
  return (
    <NextArrowWrap
      className={`${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img
        className="arrow-right"
        src={slider === 'clean' ? rightBlue : arrowRight}
        alt="halojasa/arrow-right"
      />
    </NextArrowWrap>
  )
}

export const SamplePrevArrow = props => {
  const { className, style, onClick, slider } = props
  return (
    <PrevArrowWrap
      className={`${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img
        className="arrow-left"
        src={slider === 'clean' ? leftBlue : arrowLeft}
        alt="halojasa/arrow-left"
      />
    </PrevArrowWrap>
  )
}
