/* eslint-disable */
import styled from 'styled-components'
export default styled.section`
  display: block;
  margin-top: 70px;

  @media screen and (min-width: 300px) and (max-width: 769px) {
    margin-top: 60px;
  }

  .desktop-hidden {
    display: none;
  }

  .slider-advantage {
    display: block;
    &-top {
      display: block;
      margin-top: 50px;
      p {
        display: block;
        width: 100%;
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
      }
    }
    &--button-app-store {
      display: flex;
      flex-direction: row;
      justify-content: center;
      > a {
        height: 65px;
        width: 180px;
        margin: 10px 10px 0 10px;
        > img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .slider-content {
    width: 80%;
    align-items: center;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    flex-direction: column;
    height: 300px;
    margin: 0 auto;
    text-align: center;
    h3 {
      margin: 0 0 10px 0;
      color: #ffffff;
      font-family: 'Open Sans';
      font-size: 30px;
      font-weight: bold;
      line-height: 41px;
      text-align: center;
    }
    p {
      margin: 0;
      color: #ffffff;
      font-family: 'Open Sans';
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }
  }
  .arrow-left {
    height: 50px;
    width: 50px;
    position: absolute;
    left: 0;
    font-size: 0;
    line-height: 0;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
  }
  .arrow-right {
    height: 50px;
    width: 50px;
    position: absolute;
    left: 0;
    font-size: 0;
    line-height: 0;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
    &:hover {
    }
  }
  .slick-next,
  .slick-prev {
    width: 50px;
    z-index: 10;
    height: 50px;
    transform: transition 0.7s ease-in;
    &:hover {
      opacity: 0.7;
      transform: transition 0.7s ease-in;
    }
    &:before {
      display: none;
    }
  }
  .slider-phone {
    position: relative;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    &-image {
      > div {
        position: absolute;
        top: 7px;
        bottom: 0;
        left: 38px;
        right: 38px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .slider-advantage {
    position: relative;
    display: flex;
    height: 650px;
    justify-content: space-between;
    &-right {
      width: 40%;
      position: relative;
      &:before {
        content: '';
        position: absolute;
      }
    }
    &-left {
      position: relative;
      width: 60%;
      &:before {
        content: '';
        position: absolute;
        top: 60%;
        left: -25%;
        right: 0;
        height: 330px;
        background: ${props =>
          props.background ||
          'linear-gradient(270deg, #ffd192 0%, #ff8e30 100%)'};
        transform: translateY(-50%);
      }
    }
    &-container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }
    &-background-shadow {
      position: absolute;
      right: 0;
      top: 251px;
      width: auto;
    }
  }
  .slick-dots {
    bottom: 5px !important;
    padding: 10px;
  }
  .slick-slider {
    margin-top: 60px;
  }
  .slider-advantage-dot {
    position: relative;
    width: 140px;
    margin: 0 auto;
    display: flex;
    padding: 0;
    &:before {
      position: absolute;
      content: '';
      height: 1px;
      top: 50%;
      left: 6px;
      right: 4px;
      bottom: 0px;
      background: #fff;
      transform: translateY(-50%);
      width: 100%;
    }
    &-number {
      border: 1px solid #fff;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Open Sans';
      background: ${props => props.dotBackground || '#ffb66e'};
      color: ${props => props.dotColor || '#fff'};
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;

      &:hover {
        background: #fff;
        color: #ffb66e;
      }
    }
    .slick-active {
      > div {
        color: ${props => props.dotColorActive || '#ffb66e'};
        background: ${props => props.dotBackgroundActive || '#fff'};
      }
    }
  }

  @media screen and (min-width: 300px) and (max-width: 1180px) {
    .desktop-hidden {
      margin: 0 auto;
      display: block;
    }
    .mobile-hidden {
      display: none;
    }

    .slider-advantage {
      display: unset;
      height: unset;
      &-dot {
        position: relative;
        width: 70%;
        margin: 0 auto;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 0;
        justify-content: space-between;
        &:before {
          position: absolute;
          content: '';
          height: 1px;
          top: 50%;
          left: 6px;
          right: 6px;
          bottom: 0px;
          background: #fff;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          width: auto;
        }
      }
    }

    .slider-advantage-container {
      position: relative;
    }

    .slider-advantage-right,
    .slider-advantage-left {
      width: 100%;
    }

    .slider-advantage-left::before {
      content: unset;
    }

    .slider-advantage-container {
      padding: 15px 0;
      width: 100%;
      /* background: linear-gradient(to right, #ff9c45, #ffd090);
       */
      background: ${props =>
        props.background ||
        'linear-gradient(270deg, #ffd192 0%, #ff8e30 100%)'};
    }

    .slider-content {
      height: 300px;
      width: 100%;
    }

    .slick-dots {
      bottom: -10px !important;
      padding: 10px;
    }

    .slick-prev {
      left: -22px;
    }

    .slick-next {
      right: -22px;
    }

    .slick-slider {
      margin-top: -20px;
    }

    /* .slider-advantage-dot {
      left: -35px;
    } */
    .slick-next,
    .slick-prev {
      transform: unset;
      top: unset;
      bottom: -70px;
    }
    .slick-next {
      right: 30%;
    }
    .slick-prev {
      left: 30%;
    }
    .slider-phone {
      position: relative;
      img {
        width: 320px;
        margin: 0 auto;
        height: auto;
      }
      &-image {
        position: absolute;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 320px;
        margin: 0 auto;
        > div {
          position: absolute;
          top: 85px;
          bottom: 0;
          left: 33px;
          right: 33px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  @media screen and (min-width: 300px) and (max-width: 1116px) {
    .slider-advantage-container {
      height: 1170px;
    }
  }
`

// .slick-list {
//   margin-top: -85px;
// }
