/* eslint-disable */
import React, { Component } from 'react'
import { Row, Col, Grid } from 'react-flexbox-grid'
import Slider from 'react-slick'
import { TitleOne } from '../../components/text'
import HandPhone from '../../static/images/landing-page-one/handphone/handphone.webp'
import AdvantageShadow from '../../static/images/landing-page-one/background/shadow-phone.svg'
import PlayStore from '../../static/icons/ic-play-store.webp'
import AppsStore from '../../static/icons/ic-apps-store.webp'
import { SampleNextArrow, SamplePrevArrow } from './arrow-slider'
import SliderStyle from './index.style'

class SimpleSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null
    }
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    })
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow slider={this.props.slide} />,
      prevArrow: <SamplePrevArrow slider={this.props.slide} />,
      appendDots: dots => (
        <div>
          <ul className="slider-advantage-dot"> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <div className="slider-advantage-dot-number">{i + 1}</div>
      )
    }
    const sliderOneOptions = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: false,
      prevArrow: false,
      dots: false
    }
    const { dataImage, dataText } = this.props

    return (
      <SliderStyle
        dotColor={this.props.dotColor}
        dotColorActive={this.props.dotColorActive}
        dotBackground={this.props.dotBackground}
        dotBackgroundActive={this.props.dotBackgroundActive}
        background={this.props.background}
        id="slider-advantage"
      >
        <div className="slider-advantage">
          <div className="slider-advantage-right"></div>
          <div className="slider-advantage-left"></div>
          <div className="slider-advantage-background-shadow">
            <img src={AdvantageShadow} alt="halojasa/advantage-shadow" />
          </div>

          <div className="slider-advantage-container">
            <Grid>
              <Row top="lg">
                <Col xs={12} sm={12} md={12} lg={4}>
                  <div className="slider-phone">
                    <img src={HandPhone} alt="halojasa/handphone" />
                    <div className="slider-phone-image">
                      <div>
                        <Slider
                          asNavFor={this.state.nav1}
                          ref={slider => (this.slider2 = slider)}
                          {...sliderOneOptions}
                        >
                          {dataImage.map((item, key) => {
                            return (
                              <img
                                className="image-slider-top"
                                src={item}
                                key={key}
                                alt="halojasa/slider-top"
                              />
                            )
                          })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={12} sm={12} lg={8}>
                  <div className="slider-advantage-right-content">
                    <div className="slider-advantage-top">
                      <TitleOne display="block" align="center">
                        DOWNLOAD DAN RASAKAN
                      </TitleOne>
                      <TitleOne display="block" align="center">
                        KEMUDAHANNYA
                      </TitleOne>
                      <p>Tersedia di Google Play dan AppStore</p>
                    </div>
                    <Slider
                      asNavFor={this.state.nav2}
                      ref={slider => (this.slider1 = slider)}
                      {...settings}
                    >
                      {dataText.map((item, key) => {
                        return (
                          <div key={key}>
                            <div className="slider-content">
                              <h3>{item.title}</h3>
                              <p>{item.desc}</p>
                            </div>
                          </div>
                        )
                      })}
                    </Slider>
                  </div>
                  <div className="mobile-hidden" style={{ marginTop: '70px' }}>
                    <b style={{ display: 'block', textAlign: 'center' }}>
                      Klik Untuk Download Aplikasi
                    </b>
                    <div className="slider-advantage--button-app-store">
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=halo.jasa.startup"
                        className="smaller-hover android-store"
                      >
                        <img src={PlayStore} alt="halojasa/play-store" />
                      </a>
                      <a
                        target="_blank"
                        href="https://apps.apple.com/app/apple-store/id1492671277?pt=119003762&ct=website%20halo%20jasa&mt=8"
                        className="smaller-hover ios-store"
                      >
                        <img src={AppsStore} alt="halojasa/app-store" />
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
        <div className="desktop-hidden" style={{ marginTop: '20px' }}>
          <b style={{ display: 'block', textAlign: 'center' }}>
            Klik Untuk Download Aplikasi
          </b>
          <div className="slider-advantage--button-app-store">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=halo.jasa.startup"
              className="smaller-hover android-store"
            >
              <img src={PlayStore} alt="halojasa/play-store" />
            </a>
            <a
              target="_blank"
              href="https://apps.apple.com/app/apple-store/id1492671277?pt=119003762&ct=website%20halo%20jasa&mt=8"
              className="smaller-hover ios-store"
            >
              <img src={AppsStore} alt="halojasa/app-store" />
            </a>
          </div>
        </div>
      </SliderStyle>
    )
  }
}

export default SimpleSlider

