/* eslint-disable */
import React from 'react'
import Container from '../../../../components/layouts/container'
import { Grid, Row, Col } from 'react-flexbox-grid'
import HeroBannerImage from '../../../../static/images/landing-page-one/background/hero-banner.svg'
import HeroBannerPeople from '../../../../static/images/landing-page-one/background/hero-banner-people.svg'
import HeroBannerStyle from './index.style'
import { useTranslation } from "react-i18next";

const HeroBanner = () => {
  const { t } = useTranslation();
  return (
    <HeroBannerStyle>
      <img className="hero-banner--background" src={HeroBannerImage} alt="halojasa/hero-banner-image" />
      <img className="hero-banner--people" src={HeroBannerPeople} alt="halojasa/hero-banner-people" />
      <Container className="width-reset">
        <div className="hero-banner">
          <Grid>
            <Row>
              <Col xs={6} md={6} lg={6}>
                <div className="border-box-box-sizing">
                  <p className="hero-title">
                    <span>HALO JASA</span>
                  </p>
                  <p className="hero-title">{t('pageHome.part1')}</p>
                  <h1 className="hero-seo">{t('pageHome.part27')}</h1>
                  <span className="hero-tag">#{t('pageHome.part2')}</span>
                  <h2 className="hero-seo">{t('pageHome.part28')}</h2>
                </div>
                <div>
                  <p className="hero-desc">
                    {t('pageHome.part3')}
                  </p>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    </HeroBannerStyle>
  )
}

export default HeroBanner
