import styled from 'styled-components'

export const Card = styled.div`
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 24px 20px 0 rgba(143, 116, 255, 0.1);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 12px;
  display: flex;
  height: 100%;
  justify-content: space-between;

  transition: all 0.3s ease-in;
  a {
    width: 100%;
  }

  button {
    border-radius: 22.5px;
    background: linear-gradient(270deg, #ff9700 0%, #ff7400 100%);
    padding: 13px 5px;
    width: 100%;
    display: inline-block;
    justify-content: space-around;
    font-size: 14px;
    outline: none;
    border: unset;
    align-items: center;
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    cursor: pointer;
    text-align: center;
  }
  &:hover {
    box-shadow: 0 24px 20px 0 rgba(0, 0, 0, 0.38);
  }
  .title-halo {
    transition: all 0.3s ease-in;
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
  }
  .icon-class {
    position: relative;
    top: 3px;
    margin-left: 4px;
  }
`

export const CardImage = styled.img`
  width: 100%;
  display: block;
  height: auto;
`

export const CardSpace = styled.div`
  width: 100%;
  padding: ${props => props.padding || '0'};
  padding: ${props => props.margin || '0'};
`
