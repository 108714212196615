import React from 'react'
import { TextBodyOne, CaptionOne } from '../text/'
import Icon from '../icons'
import { Card, CardImage, CardSpace } from './index.style'
import { useTranslation } from 'react-i18next'

const OurServiceCard = props => {
  const { t } = useTranslation()
  const { image, title, desc, url } = props
  return (
    <Card>
      <CardSpace margin="0 0 20px 0">
        <CardImage src={image} alt={props.altText} />
      </CardSpace>
      <TextBodyOne className="title-halo" fontWeight="bold">
        {title}
      </TextBodyOne>
      <CardSpace margin="15px 0 15px 0">
        <CaptionOne display="block" color="ui-grey" align="center">
          {desc}
        </CaptionOne>
      </CardSpace>

      <a href={url}>
        <button type="button" className="glass-hover">
          {t('pageHome.part9')}{' '}
          <Icon className="icon-class" size={12} name="chevronRight"></Icon>
        </button>
      </a>
    </Card>
  )
}

export default OurServiceCard
