import styled, { keyframes } from 'styled-components'

const animationRotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export default styled.section`
  margin-top: 70px;
  display: block;

  video {
    display: block;
    width: 100%;
  }
  .why-content {
    height: 370px;
    width: 370px;
    border-radius: 100%;
    margin: 60px auto;
    position: relative;
    .why-content {
      &-round {
        position: absolute;
        left: 40px;
        top: 45px;
        height: 280px;
        width: 280px;
        border: 2px dashed #bfaeff;
        border-radius: 100%;
        animation: ${animationRotate} 100s infinite linear;

        &-desc {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          width: 250px;
          border-radius: 100%;
          color: #222;
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          padding: 0 30px;
          &-text {
            width: 100%;
            display: block;
            transform: scale(0);
            opacity: 0;
            transition: all 2s ease;
            &.active {
              opacity: 1;
              transform: scale(1);
            }
          }

          div {
            color: #8068de;
            font-family: 'Open Sans';
            font-size: 24px;
            font-weight: bold;
            line-height: 33px;
            text-align: center;
            margin: 0;
          }
        }
        &-circle {
          width: 100%;
          height: 100%;
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 100%;
          z-index: 20;
          transition: all 2s ease 0s;
          transform: rotate(${props => props.degreeCircleTransform}deg);
          &-box {
            display: block;
            width: 70px;
            height: 70px;
            position: absolute;
            background: #fff;
            border-radius: 50%;
            text-align: center;
            line-height: 80px;
            font-size: 30px;
            z-index: 3;
            cursor: pointer;
            background: linear-gradient(180deg, #f0f0f0 0%, #dddddd 100%);
            transform: rotate(0deg);
            transition: all 1s ease 0s;
            > button {
              padding: 0;
              background: transparent;
              outline: none;
              border: none;
              cursor: pointer;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;

              > video {
                transition: all 1s ease 0s;
                height: 32px;
                width: 32px;
                transform: rotate(${props => props.degreeIconTransform}deg);
              }
            }
            &.one {
              left: 150px;
              top: 8px;
            }
            &.two {
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
            &.three {
              left: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
            &.four {
              left: 150px;
              bottom: 8px;
            }
            &.active {
              background: linear-gradient(135deg, #ff7400 0%, #ff9600 100%);
            }
          }
        }
      }
    }
  }

  .circle-animation-left {
    width: calc(100% - 370px);
  }

  .circle-animation-right {
    width: 370px;
  }

  @media screen and (min-width: 300px) and (max-width: 767px) {
    .circle-animation-left {
      width: 100%;
      text-align: center;

      .why-content-desc {
        display: none;
      }

      video {
        display: none;
      }
    }

    .why-content {
      margin: 10px auto 60px;
    }

    @media screen and (max-width: 379px) {
      .circle-animation-right,
      .circle-animation-left {
        display: none;
      }
    }

    @media screen and (min-width: 401px) {
      .circle-animation-right {
        width: 100%;
      }
    }
  }
  .circle-text-top {
    display: block;
    margin-top: 5px;
  }
  .circle-text-bottom {
    display: block;
    margin-top: 30px;
  }
  .circle-text-gif {
    display: block;
    margin-top: 24px;
  }
`
