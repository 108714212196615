/* eslint-disable */
import React, { useEffect, Fragment, useState, useCallback } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Testimonial from '../../components/testimonial'
import About from './section/about'
import WhyContent from './section/why-content'
import HeroBanner from './section/hero-banner'
import Category from './section/category'
import FixedServices from '../../components/services-fixed'
import FixedServicesResponsive from '../../components/service-fixed-responsive'
import SliderPhone from '../../components/slider-phone'
import Avatar1 from '../../static/icons/social/avatar-1.png'
import Avatar2 from '../../static/icons/social/avatar-2.png'
import Avatar3 from '../../static/icons/social/avatar-3.png'
import slide1 from '../../static/images/landing-page-one/slider-phone/slide-one.webp'
import slide2 from '../../static/images/landing-page-one/slider-phone/slide-two.webp'
import slide3 from '../../static/images/landing-page-one/slider-phone/slide-three.webp'
import slide5 from '../../static/images/landing-page-one/slider-phone/slide-five.webp'

const Home = () => {
  const { t } = useTranslation()
  const [isActiveStick, setActiveStick] = useState(true)
  const [nowPosition, setCurrentPosition] = useState(0)

  const hideStick = useCallback(() => {
    setActiveStick(false)
  }, [])

  const trackScrolling = useCallback(() => {
    const currentScrollPos = window.pageYOffset
    if (currentScrollPos > nowPosition + 100) {
      setActiveStick(false)
    } else if (currentScrollPos < nowPosition + 200) {
      setActiveStick(true)
    }
    setCurrentPosition(currentScrollPos)
  }, [nowPosition])

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling)
    return () => {
      document.removeEventListener('scroll', trackScrolling)
    }
  }, [trackScrolling])

  const handlingHelmet = () => {
    if (window.location.pathname.includes('register-vendor')) {
      return (
        <Helmet>
          <title>Register Vendor | halojasa.com</title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Daftarkan diri anda. Jadikan diri anda sebagai mitra terampil dan bermanfaat"
          />
          <meta property="og:title" content="Register Vendor | halojasa.com" />
          <meta property="og:site_name" content="Halo Jasa" />
          <meta
            property="og:url"
            content="https://halojasa.com/register-vendor/"
          />
          <meta
            property="og:description"
            content="Daftarkan diri anda. Jadikan diri anda sebagai mitra terampil dan bermanfaat"
          />
          <meta
            property="og:image"
            content="https://halojasa.com/blog/wp-content/uploads/2020/06/register-ls.png"
          />
          <meta
            property="og:image:secure_url"
            content="https://halojasa.com/blog/wp-content/uploads/2020/06/register-ls.png"
          />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta
            property="og:image"
            itemProp="image"
            content="https://halojasa.com/blog/wp-content/uploads/2020/06/register-sq.png"
          />
          <meta
            property="og:image:secure_url"
            itemProp="image"
            content="https://halojasa.com/blog/wp-content/uploads/2020/06/register-sq.png"
          />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="951866408583742" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@pthalojasa" />
          <meta name="twitter:title" content="Register Vendor | halojasa.com" />
          <meta
            name="twitter:description"
            content="Daftarkan diri anda. Jadikan diri anda sebagai mitra terampil dan bermanfaat"
          />
          <meta
            name="twitter:image:src"
            content="https://halojasa.com/blog/wp-content/uploads/2020/06/register-ls.png"
          />
          <meta
            name="p:domain_verify"
            content="4e6fcb4deba1a02d84225fad69babc0b"
          />
          <meta name="keyword" content="register vendor" />
        </Helmet>
      )
    } else {
      return (
        <Helmet>
          <title>
            Layanan Jasa On Demand Terbaik di Indonesia | halojasa.com
          </title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Aplikasi layanan jasa on demand terlengkap. Jasa servis ac, pijat panggilan, cleaning servis panggilan dan layanan perawatan kendaraan, mudah bertransaksi secara online."
          />
          <meta
            property="og:title"
            content="Layanan Jasa On Demand Terbaik di Indonesia | halojasa.com"
          />
          <meta property="og:site_name" content="Halo Jasa" />
          <meta
            property="og:description"
            content="Aplikasi layanan jasa on demand terlengkap. Jasa servis ac, pijat panggilan, cleaning servis panggilan dan layanan perawatan kendaraan, mudah bertransaksi secara online."
          />
          <meta property="og:url" content="https://halojasa.com/" />
          <meta
            property="og:image"
            content="https://halojasa.com/blog/wp-content/uploads/2020/06/home-ls.png"
          />
          <meta
            property="og:image:secure_url"
            content="https://halojasa.com/blog/wp-content/uploads/2020/06/home-ls.png"
          />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="951866408583742" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@pthalojasa" />
          <meta
            name="twitter:title"
            content="Layanan Jasa On Demand Terbaik di Indonesia | halojasa.com"
          />
          <meta
            name="twitter:description"
            content="Aplikasi layanan jasa on demand terlengkap. Jasa servis ac, pijat panggilan, cleaning servis panggilan dan layanan perawatan kendaraan, mudah bertransaksi secara online."
          />
          <meta
            name="twitter:image:src"
            content="https://halojasa.com/blog/wp-content/uploads/2020/06/home-ls.png"
          />
          <meta
            name="keyword"
            content="aplikasi on demand, demand service, layanan jasa on demand"
          />
          <meta
            name="p:domain_verify"
            content="4e6fcb4deba1a02d84225fad69babc0b"
          />
          <meta
            property="og:image"
            itemProp="image"
            content="https://halojasa.com/images/home-sq.png"
          />
          <meta
            property="og:image:secure_url"
            itemProp="image"
            content="https://halojasa.com/images/home-sq.png"
          />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />
          <link rel="canonical" href="https://halojasa.com/" />
        </Helmet>
      )
    }
  }

  return (
    <Fragment>
      {handlingHelmet()}
      <HeroBanner />
      <About />
      <Category />
      <WhyContent />
      <Testimonial
        data={[
          {
            key: 1,
            name: 'Mba Didi Arsyan',
            job: 'Socialita',
            desc: 'Aplikasi yang melengkapi gaya hidup masyarakat modern',
            avatarImage: Avatar1
          },
          {
            key: 2,
            name: 'Ibu Christine',
            job: 'Ibu rumah tangga',
            desc: 'Sangat bermanfaat dan bikin gaya hidup lebih enjoy',
            avatarImage: Avatar3
          },
          {
            key: 3,
            name: 'Bapak Beno darsono',
            job: 'Profesional',
            desc:
              'Merubah kebiasaan konvensional membantu saya memiliki waktu lebih baik',
            avatarImage: Avatar2
          },
          {
            key: 4,
            name: 'Ibu Christine',
            job: 'Ibu rumah tangga',
            desc: 'Sangat bermanfaat dan bikin gaya hidup lebih enjoy',
            avatarImage: Avatar3
          }
        ]}
      />
      <SliderPhone
        dataImage={[slide1, slide2, slide3, slide5]}
        dataText={[
          {
            title: 'Mulailah Mendaftar',
            desc:
              'Daftar di aplikasi Halo Jasa dengan menggunakan nama, nomor handphone, dan email kamu'
          },
          {
            title: 'Pilih kategori kebutuhan layanan',
            desc:
              ' Mulailah memilih berbagai layanan andalan kami, mulai dari Halo Massage, Halo Auto, Halo Fix, dan juga Halo Clean'
          },
          {
            title: 'Masukkan Informasi Pesanan',
            desc: 'Masukan alamat dan tentukan waktu pengerjaan'
          },
          {
            title: 'Konfirmasi Pemesanan Layanan',
            desc:
              ' Masukkan waktu pemesanan layanan, lokasi, metode pembayaran, dan voucher'
          },
          {
            title: 'Rating dan Ulasan',
            desc:
              'Jangan lupa untuk memberikan rating dan juga ulasan kepada vendor kami agar kami bisa lebih baik melayani anda'
          }
        ]}
      />
      {window.innerWidth > 1023 ? (
        <FixedServices />
      ) : (
        <FixedServicesResponsive />
      )}
    </Fragment>
  )
}

export default Home
