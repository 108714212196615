import styled from 'styled-components'

export const NextArrowWrap = styled.div`
  .arrow-right {
    height: 50px;
    width: 50px;
  }
`

export const PrevArrowWrap = styled.div`
  .arrow-left {
    height: 50px;
    width: 50px;
  }
`
