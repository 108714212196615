import styled from 'styled-components'

const ServiceCategoryStyles = styled.section`
  #list-service {
    position: relative;
  }
  .border-text-wrap {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .home-service-wrapper {
    position: relative;
    display: block;
    margin-top: 30px;
    height: 600px;
  }
  .service-background {
    &-right {
      position: absolute;
      right: 20px;
      height: 125px;
      width: 125px;
      bottom: 50px;
      > img {
        display: block;
        width: 100%;
      }
    }
    &-left {
      position: absolute;
      left: 0px;
      height: 165px;
      width: 165px;
      top: 270px;
      > img {
        display: block;
        width: 100%;
      }
    }
  }
  .img-background-service {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    height: auto;
    width: 100%;
    display: block;
  }

  @media screen and (min-width: 300px) and (max-width: 1023px) {
    .service-background-right {
      display: none;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 767px) {
    .home-service-wrapper {
      height: unset !important;
    }

    .home-service-content {
      width: 100%;
      flex-basis: unset;
      max-width: 100%;
      margin-bottom: 20px;
    }

    @media screen and (min-width: 400px) {
      .home-service-content {
        width: 50%;
      }
    }

    .service-background-left > img {
      display: none;
    }
  }
`

export default ServiceCategoryStyles
